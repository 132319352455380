import { HttpClient } from '@angular/common/http';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { Config } from '../../../../../../apps/acengage/src/app/config/index';
import { environment } from '../../../../../../apps/acengage/src/environments/environment';
@Component({
  selector: 'acengage-filter-table',
  templateUrl: './filter-table.component.html',
  styleUrls: ['./filter-table.component.scss'],
})
export class FilterTableComponent {
  isShowDropDown = false;
  todayDate: Date = new Date();
  @Output() updatedFilterSelection = new EventEmitter();
  @Output() clearFilters = new EventEmitter();
  @Input() set filterFields(filterData: any) {
    this.filterDetails = filterData;
    this.filterKeys = this.filterDetails ? Object.keys(this.filterDetails) : [];
    this.initLazyLoad();
  }
  filterKeys: Array<string> = [];
  filterDetails: any = [];
  numRegex = Config.numberPolicy;
  filteredData: any = {};
  @Input() set preservedFilterDtls(value: any) {
    if(value){
      this.filteredData =  JSON.parse(JSON.stringify(value));
      this.initFilterData();
    }
    // this.filteredData = value ? JSON.parse(JSON.stringify(value)) : {};
  }
  @Input() disableFilter: boolean = false;
  dateSelectOptions = [
    {
      name: 'Equal to',
      value: 'eq',
    },
    {
      name: 'Greater than or equal to',
      value: 'gte',
    },
    {
      name: 'Lesser than or equal to',
      value: 'lte',
    },
    {
      name: 'Range',
      value: 'range',
    },
  ];

  NumberListOption = [
    {
      name: 'Equals',
      value: 'eq',
    },
    {
      name: 'Not Equals',
      value: 'neq',
    },
    {
      name: 'Lesser than',
      value: 'lt',
    },
    {
      name: 'Lesser than or equal to',
      value: 'lte',
    },
    {
      name: 'Greater than',
      value: 'gt',
    },
    {
      name: 'Greater than or equal to',
      value: 'gte',
    },
    {
      name: 'Range',
      value: 'range',
    },
  ];
  selectedDateDtls: any = {};
  selectedNumberDetails: any = {};
  isShowLoader = false;

  constructor(private http: HttpClient) { }

  getFilteredCount() {
    const filteredKeys = Object.keys(this.filteredData);
    return (
      filteredKeys.filter(
        (eachKey) => this.filteredData[eachKey]?.length && eachKey !== 'search'
      )?.length || 0
    );
  } 
  checkDataExists({ index }: any) {
    const keyName: string = this.filterKeys[index];
    if (this.filterDetails[keyName]?.filter_type === 'List') {
      let lazyParams = '';
      if (this.filterDetails[keyName]?.is_lazy) {
        lazyParams = `page=${this.filterDetails[keyName].page}&limit=${this.filterDetails[keyName].limit}&search=${this.filterDetails[keyName].search}`;
        this.filterDetails[keyName].searchTextValue =
          this.filterDetails[keyName].search;
      }
      // if (!this.filterDetails[keyName]?.data?.length) {
      this.doDynamicApiCall(keyName, lazyParams);
      // }
    }
  }

  doDynamicApiCall(keyName: string, lazyParams = '') {
    let apiName: string = this.filterDetails[keyName]?.key;
    const isLazy: boolean = this.filterDetails[keyName]?.is_lazy;
    if (isLazy) {
      apiName = `${apiName}${this.filterDetails[keyName]?.key?.includes('?') ? '&' : '?'
        }${lazyParams}`;
    }
    if (apiName) {
      this.isShowLoader = true;
      const options: any = {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('login') as string)['token']
            }`,
        },
      };
      this.http.get(`${environment.api_url}${apiName}`, options).subscribe(
        ({ data }: any) => {
          this.isShowLoader = false;
          if (isLazy) {
            // this.filterDetails[keyName].data = [];
            if (this.filterDetails[keyName]?.data && data.count === this.filterDetails[keyName].data.length) {
              this.filterDetails[keyName].data = [];
            }
            this.filterDetails[keyName].count = data?.count || 0;
            this.filterDetails[keyName].data = [
              ...(this.filterDetails[keyName]?.data || []),
              ...(data?.results || []),
            ];
          } else {
            this.filterDetails[keyName].data = data?.data || [];
          }
        },
        (err: any) => (this.isShowLoader = false)
      );
    }
  }

  getLazyLoadNextData(lazyConfig: any, keyName: string) {
    if (
      lazyConfig.count > lazyConfig?.data?.length ||
      lazyConfig.search !== this.filterDetails[keyName].search
    ) {
      if (lazyConfig.search !== this.filterDetails[keyName].search) {
        this.filterDetails[keyName].data = [];
      }
      this.filterDetails[keyName].page = lazyConfig.page;
      this.filterDetails[keyName].search = lazyConfig.search;
      const lazyParams = `page=${this.filterDetails[keyName].page}&limit=${this.filterDetails[keyName].limit}&search=${this.filterDetails[keyName].search}`;
      this.doDynamicApiCall(keyName, lazyParams);
    }
  }

  selectDateRange(keyName: string) {
    if (
      this.selectedDateDtls[this.filterDetails[keyName]['filter_key']].cond !==
      'range'
    ) {
      this.selectedDateDtls[this.filterDetails[keyName]['filter_key']].to_date =
        '';
    }
  }

  selectNumber(keyName: string) {
    if (
      this.selectedNumberDetails[this.filterDetails[keyName]['filter_key']]
        .cond !== 'range'
    ) {
      this.selectedNumberDetails[
        this.filterDetails[keyName]['filter_key']
      ].to_number = '';
    }
  }

  filterButtonClick() {
    this.isShowDropDown = !this.isShowDropDown;
  }

  initLazyLoad() {
    const refLazyConfig = {
      page: 1,
      count: 0,
      limit: 10,
      search: '',
      searchTextValue: '',
    };
    this.filterKeys.forEach((eachKey) => {
      if (
        this.filterDetails[eachKey]?.is_lazy &&
        !this.filterDetails[eachKey]?.page
      ) {
        this.filterDetails[eachKey] = {
          ...this.filterDetails[eachKey],
          ...refLazyConfig,
        };
      }
      if (
        this.filterDetails[eachKey]['filter_type'] === 'Date' &&
        this.filterDetails[eachKey]?.data_type === 'local'
      ) {
       
      }
    }); 
    this.initFilterData();
  }
  toLocalDate(date: any) {
    let convertDate = moment(date, 'YYYY-MM-DD');
    return moment(convertDate).format('DD/MM/YYYY');
  }
  unixToDate(date:any){
    let time 	= new Date(date * 1000),
        month = time.getMonth() + 1,
        day		= time.getDate(),
        year	= time.getFullYear(),
        res   = day + '/' + month + '/' + year;
    
    return res;
  }

  initFilterData(isProcess?: any) {
    this.filterKeys.forEach((eachKey: string) => {
      this.filteredData = {
        ...this.filteredData,
        [this.filterDetails[eachKey]['filter_key']]:
          this.filteredData[this.filterDetails[eachKey]['filter_key']] &&
            isProcess !== 'clear'
            ? this.filteredData[this.filterDetails[eachKey]['filter_key']]
            : (isProcess === 'clear')?[]:this.filteredData[this.filterDetails[eachKey]['filter_key']],
      };
      if (this.filterDetails[eachKey]['filter_type'] === 'Date') {
        let dateObject: any = {
          from_date: '',
          to_date: '',
          cond: 'eq',
          data_type: this.filterDetails[eachKey]?.data_type
            ? this.filterDetails[eachKey].data_type
            : '',
        };
        if (
          this.filteredData[this.filterDetails[eachKey]['filter_key']]
            ?.length === 3 &&
          isProcess !== 'clear'
        ) {
          if(this.filterDetails[eachKey]?.data_type === 'local'){
            dateObject['from_date'] = new Date(this.filteredData[this.filterDetails[eachKey]['filter_key']][0]);
            dateObject['to_date'] = new Date(this.filteredData[this.filterDetails[eachKey]['filter_key']][1]);
          }else{
            const timestampInMilliseconds1 = this.filteredData[this.filterDetails[eachKey]['filter_key']][0] * 1000;
            dateObject['from_date'] = new Date(
              timestampInMilliseconds1
            );
            const timestampInMilliseconds2 = this.filteredData[this.filterDetails[eachKey]['filter_key']][1] * 1000;
            dateObject['to_date'] = new Date(
              timestampInMilliseconds2
            );
          }
          dateObject['cond'] =
            this.filteredData[this.filterDetails[eachKey]['filter_key']][2];
          dateObject['data_type'] = this.filterDetails[eachKey]?.data_type
            ? this.filterDetails[eachKey].data_type
            : '';
        } else if (
          this.filteredData[this.filterDetails[eachKey]['filter_key']]
            ?.length === 2 &&
          isProcess !== 'clear'
        ) {
          // dateObject['from_date'] = new Date(
          //   this.filteredData[this.filterDetails[eachKey]['filter_key']][0]
          // );
          if(this.filterDetails[eachKey]?.data_type === 'local'){
            dateObject['from_date'] = new Date(this.filteredData[this.filterDetails[eachKey]['filter_key']][0]);
          }else{
            const timestampInMilliseconds1 = this.filteredData[this.filterDetails[eachKey]['filter_key']][0] * 1000;
            dateObject['from_date'] = new Date(
              timestampInMilliseconds1
            );
          }
          dateObject['to_date'] = '';
          dateObject['cond'] =
            this.filteredData[this.filterDetails[eachKey]['filter_key']][1];
          dateObject['data_type'] = this.filterDetails[eachKey]?.data_type
            ? this.filterDetails[eachKey].data_type
            : '';
        }
        this.selectedDateDtls[this.filterDetails[eachKey]['filter_key']] =
          dateObject;
      }
      if (
        this.filterDetails[eachKey]['filter_type'] === 'Number' ||
        this.filterDetails[eachKey]['filter_type'] === 'Decimal'
      ) {
        let numberObject: any = {
          from_number: '',
          to_number: '',
          cond: 'eq',
          data_type: this.filterDetails[eachKey]?.data_type
            ? this.filterDetails[eachKey].data_type
            : '',
        };
        if (
          this.filteredData[this.filterDetails[eachKey]['filter_key']]
            ?.length === 3 &&
          isProcess !== 'clear'
        ) {
          numberObject['from_number'] =
            this.filteredData[this.filterDetails[eachKey]['filter_key']][0];
          numberObject['to_number'] =
            this.filteredData[this.filterDetails[eachKey]['filter_key']][1];
          numberObject['cond'] =
            this.filteredData[this.filterDetails[eachKey]['filter_key']][2];
          numberObject['data_type'] = this.filterDetails[eachKey]?.data_type
            ? this.filterDetails[eachKey].data_type
            : '';
        } else if (
          this.filteredData[this.filterDetails[eachKey]['filter_key']]
            ?.length === 2 &&
          isProcess !== 'clear'
        ) {
          numberObject['from_number'] =
            this.filteredData[this.filterDetails[eachKey]['filter_key']][0];
          numberObject['to_number'] = '';
          numberObject['cond'] =
            this.filteredData[this.filterDetails[eachKey]['filter_key']][1];
          numberObject['data_type'] = this.filterDetails[eachKey]?.data_type
            ? this.filterDetails[eachKey].data_type
            : '';
        }
        this.selectedNumberDetails[this.filterDetails[eachKey]['filter_key']] =
          numberObject;
      }
      if (
        isProcess === 'clear' &&
        this.filterDetails[eachKey]['filter_type'] === 'List' &&
        this.filterDetails[eachKey]?.is_lazy &&
        (this.filterDetails[eachKey]?.search ||
          this.filterDetails[eachKey]?.searchTextValue)
      ) {
        this.filterDetails[eachKey] = {
          ...this.filterDetails[eachKey],
          data: [],
          page: 1,
          limit: 10,
          search: '',
          searchTextValue: '',
        };
        const lazyParams = `page=${this.filterDetails[eachKey].page}&limit=${this.filterDetails[eachKey].limit}&search=${this.filterDetails[eachKey].search}`;
        this.doDynamicApiCall(eachKey, lazyParams);
      }
    });
  }

  minMaxDate(keyName: string, isToDate: boolean = false) {
    if (isToDate) {
      return this.selectedDateDtls[this.filterDetails[keyName]['filter_key']]
        ?.from_date
        ? new Date(
          this.selectedDateDtls[
            this.filterDetails[keyName]['filter_key']
          ].from_date
        )
        : null;
    } else {
      return this.selectedDateDtls[this.filterDetails[keyName]['filter_key']]
        ?.to_date
        ? new Date(
          this.selectedDateDtls[
            this.filterDetails[keyName]['filter_key']
          ].to_date
        )
        : null;
    }
  }

  formDateData(dateKeyNames: Array<string>) {
    dateKeyNames.forEach((eachKeyName: string) => {
      if (
        this.selectedDateDtls[eachKeyName].from_date &&
        this.selectedDateDtls[eachKeyName].cond
      ) {
        this.filteredData[eachKeyName] = [];
        const selectedFromDate: string =
          this.selectedDateDtls[eachKeyName].from_date;
        if (
          this.selectedDateDtls[eachKeyName].from_date &&
          (this.selectedDateDtls[eachKeyName].cond === 'lte' ||
            this.selectedDateDtls[eachKeyName].cond === 'gte')
        ) {
          let timestamp;
          if (this.selectedDateDtls[eachKeyName]?.data_type === 'local') {
            timestamp = moment(selectedFromDate).format('YYYY-MM-DD');
          } else {
            const dateToday =
              this.selectedDateDtls[eachKeyName].cond === 'lte'
                ? moment(selectedFromDate)
                  .set('hour', 23)
                  .set('minute', 59)
                  .set('second', 59)
                : new Date(selectedFromDate);
            timestamp = moment(dateToday).unix();
          }
          this.filteredData[eachKeyName] = [
            timestamp,
            ...this.filteredData[eachKeyName],
          ];
        } else if (
          this.selectedDateDtls[eachKeyName].from_date &&
          this.selectedDateDtls[eachKeyName].cond === 'eq'
        ) {
          let timestamp;
          if (this.selectedDateDtls[eachKeyName]?.data_type === 'local') {
            timestamp = moment(selectedFromDate).format('YYYY-MM-DD');
            this.filteredData[eachKeyName] = [
              timestamp,
              ...this.filteredData[eachKeyName],
            ];
          } else {
            const timeAndDate = moment(selectedFromDate)
              .set('hour', 0)
              .set('minute', 0)
              .set('second', 0);
            const timeAndDate1 = moment(selectedFromDate)
              .set('hour', 23)
              .set('minute', 59)
              .set('second', 59);
            timestamp = moment(timeAndDate).unix();
            const timestamp1 = moment(timeAndDate1).unix();
            this.filteredData[eachKeyName] = [
              timestamp,
              timestamp1,
              ...this.filteredData[eachKeyName],
            ];
          }
        } else {
          let timestamp;
          if (this.selectedDateDtls[eachKeyName]?.data_type === 'local') {
            timestamp = moment(selectedFromDate).format('YYYY-MM-DD');
          } else {
            const timeAndDate = moment(selectedFromDate)
              .set('hour', 0)
              .set('minute', 0)
              .set('second', 0);
            timestamp = moment(timeAndDate).unix();
          }
          this.filteredData[eachKeyName] = [
            timestamp,
            ...this.filteredData[eachKeyName],
          ];
        }
        if (this.selectedDateDtls[eachKeyName].cond) {
          this.filteredData[eachKeyName] = [
            ...this.filteredData[eachKeyName],
            this.selectedDateDtls[eachKeyName].cond,
          ];
        }
        if (
          this.selectedDateDtls[eachKeyName].to_date &&
          this.selectedDateDtls[eachKeyName].cond === 'range'
        ) {
          const selectedToDate: string =
            this.selectedDateDtls[eachKeyName].to_date;
          let timestamp;
          if (this.selectedDateDtls[eachKeyName]?.data_type === 'local') {
            timestamp = moment(selectedToDate).format('YYYY-MM-DD');
          } else {
            const timeAndDate = moment(selectedToDate)
              .set('hour', 23)
              .set('minute', 59)
              .set('second', 59);
            timestamp = moment(timeAndDate).unix();
          }
          this.filteredData[eachKeyName].splice(1, 0, timestamp);
        }
      } else {
        this.filteredData[eachKeyName] = [];
      }
    });
  }

  prepareNumberData(numberKeyName: Array<string>) {
    numberKeyName.forEach((eachKeyName: string) => {
      if (
        this.selectedNumberDetails[eachKeyName].from_number &&
        this.selectedNumberDetails[eachKeyName].cond
      ) {
        this.filteredData[eachKeyName] = [];
        const selectedFromNumber: any = Number(
          this.selectedNumberDetails[eachKeyName].from_number
        );
        if (
          this.selectedNumberDetails[eachKeyName].from_number &&
          this.selectedNumberDetails[eachKeyName].cond !== 'range'
        ) {
          this.filteredData[eachKeyName] = [
            selectedFromNumber,
            this.selectedNumberDetails[eachKeyName].cond,
          ];
        } else if (
          this.selectedNumberDetails[eachKeyName].from_number &&
          this.selectedNumberDetails[eachKeyName].cond === 'range'
        ) {
          const toNumber: any = this.selectedNumberDetails[eachKeyName]
            .to_number
            ? Number(this.selectedNumberDetails[eachKeyName].to_number)
            : '';
          if (toNumber !== undefined) {
            this.filteredData[eachKeyName] = [
              selectedFromNumber,
              toNumber,
              this.selectedNumberDetails[eachKeyName].cond,
            ];
          } else {
            this.filteredData[eachKeyName] = [
              selectedFromNumber,
              this.selectedNumberDetails[eachKeyName].cond,
            ];
          }
        } else {
          this.filteredData[eachKeyName] = [];
        }
      } else {
        this.selectedNumberDetails[eachKeyName].cond = 'eq';
        this.selectedNumberDetails[eachKeyName].from_number = '';
        this.selectedNumberDetails[eachKeyName].to_number = '';
        this.filteredData[eachKeyName] = [];
      }
    });
  }
  closeFilter() {
    this.initFilterData('clear');
    this.clearFilters.emit(true);
    this.updatedFilterSelection.emit(this.filteredData);
    this.isShowDropDown = false;
  }

  updateFilterSelection() {
    const dateKeyNames: Array<string> = this.selectedDateDtls
      ? Object.keys(this.selectedDateDtls)
      : [];
    this.formDateData(dateKeyNames);
    const numberKeyName: Array<string> = this.selectedNumberDetails
      ? Object.keys(this.selectedNumberDetails)
      : [];
    this.prepareNumberData(numberKeyName);
    this.updatedFilterSelection.emit(this.filteredData);
    this.isShowDropDown = false;
  }
}
