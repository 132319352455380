import { createAction, props } from '@ngrx/store';
import { ToasterConfig } from '../models/toaster.model';
import { loginPayload, MenuPayload, PermissionPayload } from './app.model';

export const appToken = createAction('[ App ] token', props<loginPayload>());

export const appLoginPayload = createAction('[ App ] loginPayload', props<loginPayload>());

export const showToaster = createAction('[Toaster] Show Toster', props<ToasterConfig>());

export const showLoader = createAction('[Loader] Triggered', props<{ showLoader: boolean }>());

// side menu

export const sideMenuBar = createAction('[Side Menu] side menu bar');

export const sideMenuBarSuccess = createAction('[Side Menu] side menu success',props<{menuItem :MenuPayload[]}>());

export const sideMenuBarFailure = createAction('[Side Menu] side menu success', props<{ error: string }>());

export const sideMenuPermissionBar = createAction('[Side Menu] side menu bar',props<{payload: PermissionPayload}>);

export const sideMenuBarPermissionSuccess = createAction('[Side Menu] side menu success',props<{menuItem :MenuPayload[]}>());

export const sideMenuBarPermissionFailure = createAction('[Side Menu] side menu success', props<{ error: string }>());

export const selectedMenu = createAction('[Side menu] menu access', props<{ selectedMenu: number }>());

export const selectedSubMenu = createAction('[Side menu] menu access', props<{ selectedSubMenu: number }>());

// row click

export const isRowClicked = createAction('[isRowClicked] Is Row Clicked', props<{ isRowClicked: boolean }>());
export const isSearchAvailable = createAction('[isSearchAvailable] Is Search Available', props<{ isSearchAvailable: boolean }>());
