<!-- <acengage-dialog [openModel]="openQueryModel" [closable]="false" (modelStateUpdate)="questionsModelState($event)"> -->
<div class="add-query--container">
  <div class="add-query--content" *ngIf="addQuery">
    <div class="add-query-head">
      <h3>{{isEdit ? 'Edit' :'Add'}} Query</h3>
      <!-- <div class="add-query-close" (click)="questionsModelState(false)">
          <img src="shared-assets/images/close.svg">
        </div> -->
    </div>
    <form [formGroup]="addQuery">
      <div class="add-query-input">
        <div class="add-query-input-content select">
          <acengage-tree-dropdown
            [treeValue]="interventionDetails?.call_status?.choice ? interventionDetails?.call_status?.choice : (queryData?.node_name?.choice || '')"
            [placeholder]="'Select Query *'" [firstNodeType]="'trees'" [showSubmit]="true" [data]="treeList"
            [isSelectAnyLevel]="false" (submitClicked)="onSubmit($event);"
            (treeId)="isUpdateCallTreeTouched = true; selectedTreeID = $event;onSubmit($event); (!selectedTreeID ? clearChoice() : null)">
          </acengage-tree-dropdown>
          <div class="form-error-message" *ngIf="!selectedTreeID && isUpdateCallTreeTouched">
            Query is required
          </div>
          <ng-container *ngIf="interventionDetails?.call_status?.tree || interventionDetails?.tree">
            <h5 class="name-truncate" [pTooltip]="interventionDetails.tree || interventionDetails.call_status.tree"
              [tooltipDisabled]="(interventionDetails?.call_status?.tree && interventionDetails?.call_status?.tree?.length <= 20) || (interventionDetails.tree && interventionDetails.tree.length <= 20)">
              {{interventionDetails.tree || interventionDetails.call_status.tree}}</h5>
          </ng-container>
          <h5 class="tree__values" *ngIf="">
            {{interventionDetails.tree || interventionDetails.call_status.tree}}</h5>
        </div>
        <div class="add-query-input-content " formArrayName="recruiter_email_id">
          <div *ngFor="let eachEmailRecurit of customField().controls; let emailRecuritIndex = index;">
            <div class="add-query-input last__index__border">
              <div class="add-query-input-content select">
                <acengage-text-input [formControlName]="emailRecuritIndex" [inputType]="'text'" [maxLength]="60"
                  [placeholder]="'Email *'" (ngModelChange)="checkAlreadyEmail(customField(),emailRecuritIndex)">
                </acengage-text-input>
                <acengage-form-error [control]="eachEmailRecurit" validatorName="required">
                  Email is required
                </acengage-form-error>
                <acengage-form-error [control]="eachEmailRecurit" validatorName="pattern">
                  Email is invalid
                </acengage-form-error>
                <acengage-form-error [control]="eachEmailRecurit" validatorName="duplicated">
                  Email already entered
                </acengage-form-error>
              </div>
              <div class="add-query-input-content--link">
                <div class="add-query-input-content--link--add"
                  *ngIf="emailRecuritIndex === (customField().controls.length - 1)" (click)="addFormArrayData();">
                  <i class="pi pi-plus"></i>
                  <span>Add Recruiter Email</span>
                </div>
                <div class="add-query-input-content--link--remove"
                  *ngIf="emailRecuritIndex !== (customField().controls.length - 1)"
                  (click)="removeFormArrayData(emailRecuritIndex);">
                  <i class="pi pi-trash"></i>
                  <span>Remove Recruiter Email</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="add-query-input-content text-area desc">
          <acengage-text-area [placeholder]="'Enter Description *'" formControlName="description"></acengage-text-area>
          <acengage-form-error [control]="addQuery.controls['description']" validatorName="required">
            Description is required.
          </acengage-form-error>
        </div>
        <div class="add-query-input-content status select" *ngIf="isEdit">
          <acengage-select [disabled]="queryData?.status === 'Closed'" [options]="queryStatus" formControlName="status"
            [placeHolder]="'Select Query Status'"></acengage-select>
          <acengage-form-error [control]="addQuery.controls['status']" validatorName="required">
            Description is required.
          </acengage-form-error>
        </div>
        <div class="attachment-section-file">
          <h4>Attachments</h4>
          <acengage-fileupload (uploadedData)="attachmentsFileUpload($event)" [maxFileSize]="10000000"
            [acceptType]="'.csv,.xlsx,.pdf,.doc, .docx,  .jpeg, .jpg, .png,.ppt'"
            [existingFiles]="uploadedOtherFilesForEdit" [name]="'Attachments'" [deleteFile]="true"
            (validationToaster)="showToaster('error', '', $event);"
            (deleteUploadFile)="setRemovedFileID($event);"></acengage-fileupload>
          <acengage-form-error [control]="addQuery.controls['attachments']" validatorName="required">
            Attachments is required.
          </acengage-form-error>
        </div>
        <div class="add-query-input-content text-area">
          <acengage-text-area [placeholder]="'Enter Recommendation *'"
            formControlName="recommendation"></acengage-text-area>
          <acengage-form-error [control]="addQuery.controls['recommendation']" validatorName="required">
            Recommendation is required.
          </acengage-form-error>
        </div>
        <div class="add-query-input-content status" *ngIf="isEdit">
          <acengage-text-area [placeholder]="'Enter Notes '" formControlName="notes"></acengage-text-area>
          <acengage-form-error [control]="addQuery.controls['notes']" validatorName="required">
            Notes is required.
          </acengage-form-error>
        </div>
        <div class="add-query-input-content status" *ngIf="isEdit">
          <acengage-text-area [placeholder]="'Enter Resolution '" formControlName="resolution"></acengage-text-area>
          <acengage-form-error [control]="addQuery.controls['resolution']" validatorName="required">
            Resolution is required.
          </acengage-form-error>
        </div>
      </div>
      <div class="add-query-btn">
        <div class="add-query-btn-cntr">
          <div (click)="navigateFromBreadcrumbBtn()">
            <img src="shared-assets/images/close-bl.svg">
            <span>Cancel</span>
          </div>
        </div>
        <div class="add-query-btn-cntr">
          <acengage-button buttonText="Submit" (buttonClicked)="querySubmit()"
            [isBtnDisable]="addQuery.invalid || !selectedTreeID ">
            <img src="shared-assets/images/general_icons/add_bg_dark.svg" alt="icon" class="img" />
          </acengage-button>
        </div>
      </div>
    </form>
  </div>
</div>
<!-- </acengage-dialog> -->