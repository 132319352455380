import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { SelectOption } from '@acengage/app/app/models/select.model';
import { QuestionsForm, QuestionTypeServiceInterventionResponse } from '@acengage/app/app/question-bank/models/add-question-bank.model';
import { QuestionsService } from '@acengage/app/app/services/question-bank.service';
import { Store } from '@ngrx/store';
import * as AppActions from '@acengage/app/app/state/app.actions';
import { AppState } from '@acengage/app/app/state/app.state';
import { AcengageTreeForm } from '../../acengage-tree/models/add-acengage-tree.interface';
import { AcengageTreeService } from '../../services/acengage-tree.service';

@Component({
  selector: 'acengage-add-edit-questions',
  templateUrl: './add-edit-questions.component.html',
  styleUrls: ['./add-edit-questions.component.scss'],
})
export class AddEditQuestionsComponent implements OnInit {
  constructor(private questionService: QuestionsService,
    private appStore: Store<AppState>,
    private treeService: AcengageTreeService,) { }
  @Input() isEdit = false;
  @Input() isShowServices = true;
  @Input() questionForm: FormGroup<QuestionsForm>;
  @Input() viewOnly = false;
  @Input() acengageTreeForm: AcengageTreeForm = {
    service: null,
    heading: null,
    subservice: null,
    options: []
  };
  @Input() treeMappingFields: SelectOption[] = [];
  @Input() nestedQuestions: any[] = [];
  @Output() saveClicked = new EventEmitter<any>();
  @Output() discardClicked = new EventEmitter<null>();
  @Output() typeChanged = new EventEmitter<number>();
  @Output() addCheckboxOptionClicked = new EventEmitter<null>();
  @Output() removeCheckboxOptionClicked = new EventEmitter<number>();
  @Output() addFollowUpClicked = new EventEmitter<number>();
  @Output() addQuestionClicked = new EventEmitter<number>();
  @Output() removeFollowUpClicked = new EventEmitter<number>();
  @Output() removeNestClicked = new EventEmitter<number>();
  @Output() handleOnRate = new EventEmitter<number>();
  @Output() removeQuestionClicked = new EventEmitter<number>();
  // @Output() updateMainFormForNested = new EventEmitter<number>();
  @Output() sendMappingFields = new EventEmitter<SelectOption[]>();
  @ViewChild('treeFormValidation') treeFormValidation: NgForm;
  processType = 'add';

  questionTypes: SelectOption[] = [];
  textareaNest=false;
  starNest=false;
  treeNest=false;
  starVal:any=0;
  boxNest=false;
  disableBtn=false;
  sliderNest=false;
  ngOnInit(): void {
    this.getQuesTypes();
    if (!this.isEdit) {
      this.addOptions();
    }else{
      if(this.questionForm.value['followup_question']){
        if(this.questionForm.value.type === 1){
          this.starNest=true; 
          this.starVal=this.questionForm.value['star']
        }else if(this.questionForm.value.type === 6){
          this.treeNest=true; 
        }
      }
    }
  }

  disableSaveBtn() {
    this.buttonType()
    if (this.questionForm.value.type === 6) {
      return this.questionForm.invalid || this.treeFormValidation?.invalid;
    } else { 
        return this.questionForm.invalid;
     }

    // if (this.questionForm.value.type === 6) {
    //   return this.questionForm.invalid || this.treeFormValidation?.invalid;
    // } else {
    //   if (!this.isEdit) {
    //     return this.questionForm.invalid;
    //   }else{
    //     return false
    //   }
    // }
  }
  buttonType(){
    if(this.questionForm.value.type === 3 || this.questionForm.value.type === 4){
      this.disableBtn=false;
      if(!this.questionForm.invalid){
        if(this.questionForm.value.options?.length){
          this.questionForm.value.options.map((z:any)=>{
            if(z?.nested_question){
              if(z.nested_question.options.length){
                z.nested_question.options.map((xy:any)=>{
                  if(xy['choice']===''){
                    this.disableBtn=true;
                  }
                })
              }
              if(z.nested_question.question===''){
                this.disableBtn=true;
              }
            }
            if(z?.followup_question===''){ 
                this.disableBtn=true; 
            }
          })
        }
      }
    } 
    if(this.starNest){
      this.disableBtn=false;
      if(this.questionForm.value.followup_question==='' || this.questionForm.value.followup_question===null || this.questionForm.value.followup_question===null || this.questionForm.value.star===null || this.questionForm.value.star===0){
        this.disableBtn=true; 
      }
    }
    if(this.questionForm.value.type === 2){
      if(this.sliderNest){
        this.disableBtn=false;
        if(this.questionForm.value.followup_question==='' || this.questionForm.value.followup_question===null || 
        this.questionForm.value.follow_up_from===null || this.questionForm.value.follow_up_to===null ){
          this.disableBtn=true; 
        }
      }
      else{
        this.disableBtn=false;
      }
    }
  }

  // ngAfterViewChecked() {
  //   if (this.processType !== 'view' && this.treeFormValidation) {
  //     this.treeFormValidation.valueChanges?.subscribe((value) => {
  //       this.treeFormValidation.invalid || !this.acengageTreeForm?.options?.length;
  //     })
  //   }
  // }
  addFollowupQuestion(){
    this.sliderNest=true;
  }
  getQuesTypes() {
    this.showLoaderTrigger(true);
    this.questionService.getQuestionTypes().subscribe((quesTypeResp: any) => {
      this.questionTypes = quesTypeResp?.data?.map((eachType: QuestionTypeServiceInterventionResponse) => {
        const formSelectOption: SelectOption = {
          name: eachType.name,
          value: eachType.id
        };
        return formSelectOption;
      }) || [];
      this.showLoaderTrigger(false);
    });
  }

  addOptions() {
    this.acengageTreeForm.options.push(this.treeService.newNodeOptionFG(1));
    this.acengageTreeForm.options = [...this.acengageTreeForm.options]
  }

  get questionFormControls() {
    return this.questionForm.controls;
  }

  showLoaderTrigger(isShow: boolean) {
    this.appStore.dispatch(AppActions.showLoader({ showLoader: isShow }));
  }
  removeRating(){
    this.starNest=false;
    this.removeNestClicked.emit()
  }
  setHandleOnRate(e:number){
    this.handleOnRate.emit(e);
  }
}
