import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import { TableHeader } from '@acengage/ui/models/table.model';

@Component({
  selector: 'acengage-edit-table-columns',
  templateUrl: './edit-table-columns.component.html',
  styleUrls: ['./edit-table-columns.component.scss'],
})
export class EditTableColumnsComponent implements OnChanges {
  constructor() {}
  filteredValue: string;
  checkAllColumns = true;
  columnsList: TableHeader[] = [];
  _keepColumnList: TableHeader[] = [];
  originalColumnsList: TableHeader[] = [];
  @Input() disableBtn = false;
  @Input() set headerList(values: TableHeader[]) {
    this.originalColumnsList = [...values];
    this.mapOriginalCols();
    this.initColumnList();
  }
  @Output() saveChanges = new EventEmitter<TableHeader[]>();
  isShowEditPopup = false;
  draggedColumn: TableHeader | null;
  draggedColumnIndex: any;

  ngOnChanges() {
    this.checkAllColumnsChecked();
  }

  mapOriginalCols() {
    this._keepColumnList = [
      ...this.originalColumnsList.map((col: TableHeader) => ({
        ...col,
        isEditShow: true,
      })),
    ];
  }

  filterColumnName() {
    if (this.filteredValue) {
      this.columnsList =
        this._keepColumnList?.map((eachValue: TableHeader) => {
          eachValue.isEditShow = eachValue?.headerName
            ?.toLowerCase()
            ?.includes(this.filteredValue?.toLowerCase());
          return eachValue;
        }) || [];
    } else {
      this.initColumnList();
    }
  }

  initColumnList() {
    this.columnsList = this._keepColumnList.map((col) => {
      return { ...col, isEditShow: true };
    });
  }

  checkDisable() {
    return this.columnsList.every((eachColumn: any) => !eachColumn.isShow);
  }

  checkColumn(event: any, checkColumn: any) {
    if (checkColumn === 'all') {
      this.columnsList = this.columnsList.map((eachColumn: TableHeader) => {
        eachColumn.isShow = event.checked;
        return eachColumn;
      });
    } else {
      this.columnsList = this.columnsList.map(
        (eachColumn: TableHeader, eachColIndex: number) => {
          if (eachColIndex === checkColumn) {
            eachColumn.isShow = event.checked;
          }
          return eachColumn;
        }
      );
      this.checkAllColumnsChecked();
    }
    this._keepColumnList = [
      ...this.columnsList.map((eachCol) => ({ ...eachCol })),
    ];
  }

  closeInBtnClick() {
    if (this.isShowEditPopup) {
      this.closePopup('clear');
    } else {
      this.isShowEditPopup = true;
    }
  }

  checkAllColumnsChecked() {
    this.checkAllColumns = this.columnsList.every(
      (eachColumn: TableHeader) => eachColumn.isShow
    );
  }

  closePopup(type: string) {
    if (type === 'save') {
      this.saveChanges.emit(this.columnsList);
    } else {
      this.mapOriginalCols();
      this.columnsList = this._keepColumnList;
      this.checkAllColumnsChecked();
    }
    this.isShowEditPopup = false;
    this.filteredValue = '';
    this.initColumnList();
  }

  dragStart(event: DragEvent, currentColumn: TableHeader, colIndex: number) {
    this.draggedColumn = currentColumn;
    this.draggedColumnIndex = colIndex;
  }

  drop(event: any, dropColumnIndex: number) {
    if (this.draggedColumn) {
      this._keepColumnList.splice(this.draggedColumnIndex, 1);
      this._keepColumnList.splice(dropColumnIndex, 0, this.draggedColumn);
      this.columnsList = this._keepColumnList;
      this.draggedColumn = null;
      this.draggedColumnIndex = null;
    }
  }

  dragEnd(event: any) {
    this.draggedColumn = null;
    this.draggedColumnIndex = null;
  }
}
