import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideMenubarComponent } from './side-menubar/side-menubar.component';
import { TopbarComponent } from './topbar/topbar.component';
import { UiModule } from '@acengage/ui/lib/ui.module';
import { BottomBarComponent } from './bottom-bar/bottom-bar.component';
import { PermissionToggleOnOffComponent } from './permission-toggle-on-off/permission-toggle-on-off.component';
import { PasswordToggleComponent } from './password-toggle/password-toggle.component';
import { FormErrorComponent } from './form-error/form-error.component';
import { SixDigitOtpComponent } from './six-digit-otp/six-digit-otp.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromApp from '../state/app.reducers';
import { AppEffects } from '../state/app.effects';
import { AddEditQuestionsComponent } from './add-edit-questions/add-edit-questions.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { QuestionCreationServiceSelectionComponent } from './add-edit-questions/question-creation-service-selection/question-creation-service-selection.component';
import { SliderQuestionTypeComponent } from './add-edit-questions/question-types/slider-question-type/slider-question-type.component';
import { ChoiceQuestionTypeComponent } from './add-edit-questions/question-types/choice-question-type/choice-question-type.component';
import { AddEditTreeViewComponent } from './add-edit-tree-view/add-edit-tree-view.component';
import { AcengageTreeServiceSelectionComponent } from './add-edit-tree-view/acengage-tree-service-selection/acengage-tree-service-selection.component';
import { TreeReasonFieldsComponent } from './add-edit-tree-view/tree-reason-fields/tree-reason-fields.component';
import { InterventionMenuComponent } from './intervention-menu/intervention-menu.component';
import { TreeDropdownComponent } from './tree-dropdown/tree-dropdown.component';
import { AddEditQueryComponent } from './add-edit-query/add-edit-query.component';
import { TooltipModule } from 'primeng/tooltip';
import { ReadMoreTextComponent } from './read-more-text/read-more-text.component';
import { AddressFormFieldsComponent } from './address-form-fields/address-form-fields.component';
import { MessagePopupComponent } from './message-popup/message-popup.component';
import { DocViewComponent } from './doc-view/doc-view.component';
import { NestedChoiceQuestionComponent } from './add-edit-questions/question-types/nested-choice-question/nested-choice-question.component';
import { NestedFollowupQuestionComponent } from './add-edit-questions/question-types/nested-followup-question/nested-followup-question.component';
import { UserInputPopupComponent } from './user-input-popup/user-input-popup.component';
import { NgageContinuityComponent } from './ngage-continuity/ngage-continuity.component';
import { TataDialerRecordingsComponent } from './tata-dialer-recordings/tata-dialer-recordings.component';
import { BuddyInductionComponent } from './buddy_induction/buddy-induction.component';
import { WelcomeEmailsComponent } from './welcome_emails/welcome-emails.component';

@NgModule({
  declarations: [
    SideMenubarComponent,
    BottomBarComponent,
    TopbarComponent,
    PermissionToggleOnOffComponent,
    PasswordToggleComponent,
    FormErrorComponent,
    SixDigitOtpComponent,
    AddEditQuestionsComponent,
    QuestionCreationServiceSelectionComponent,
    SliderQuestionTypeComponent,
    ChoiceQuestionTypeComponent,
    AddEditTreeViewComponent,
    AcengageTreeServiceSelectionComponent,
    TreeReasonFieldsComponent,
    InterventionMenuComponent,
    TreeDropdownComponent,
    AddEditQueryComponent,
    ReadMoreTextComponent,
    AddressFormFieldsComponent,
    MessagePopupComponent,
    DocViewComponent,
    NestedChoiceQuestionComponent,
    NestedFollowupQuestionComponent,
    UserInputPopupComponent,
    NgageContinuityComponent,
    TataDialerRecordingsComponent,
    BuddyInductionComponent,
    WelcomeEmailsComponent
  ],
  imports: [
    CommonModule,
    TooltipModule,
    UiModule,
    ReactiveFormsModule,
    FormsModule,
    StoreModule.forFeature(fromApp.APP_FEATURES, fromApp.AppReducer),
    EffectsModule.forFeature([AppEffects]),
  ],
  exports: [
    SideMenubarComponent,
    BottomBarComponent,
    TopbarComponent,
    PermissionToggleOnOffComponent,
    PasswordToggleComponent,
    FormErrorComponent,
    AddEditQuestionsComponent,
    SliderQuestionTypeComponent,
    ChoiceQuestionTypeComponent,
    AddEditTreeViewComponent,
    InterventionMenuComponent,
    TreeDropdownComponent,
    AddEditQueryComponent,
    ReadMoreTextComponent,
    AddressFormFieldsComponent,
    MessagePopupComponent,
    DocViewComponent,
    NestedChoiceQuestionComponent,
    NestedFollowupQuestionComponent,
    UserInputPopupComponent,
    NgageContinuityComponent,
    TataDialerRecordingsComponent,
    BuddyInductionComponent,
    WelcomeEmailsComponent
  ],
})
export class SharedModule {}
