export const MaritalStatus = [
  { name: 'Divorced', value: 4 },
  { name: 'Married', value: 1 },
  { name: 'Separated', value: 5 },
  { name: 'Unmarried', value: 2 },
  { name: 'Widow / Widower', value: 3 },
];
export const Genders = [
  { name: 'Female', value: 2 },
  { name: 'Male', value: 1 },
  { name: 'Not preferred', value: 4 },
  { name: 'Others', value: 3 },
];
export const TypeOfJobs = [
  { name: 'Contract', value: 4 },
  { name: 'Full-time', value: 2 },
  { name: 'Intern', value: 3 },
  { name: 'Part-time', value: 1 },
];
export const CounselorType = [
  { name: 'Amber', value: 4 },
  { name: 'Elite', value: 1 },
  { name: 'Green', value: 5 },
  { name: 'Red', value: 3 },
  // { name: "Senior", value: 2 },
];
export const CallType = [
  { name: 'Both', value: 3 },
  { name: 'Domestic', value: 2 },
  { name: 'International', value: 1 },
];
export const SliderValues = [
  {
    name: '0',
    value: 0,
  },
  {
    name: '1',
    value: 1,
  },
  {
    name: '2',
    value: 2,
  },
  {
    name: '3',
    value: 3,
  },
  {
    name: '4',
    value: 4,
  },
  {
    name: '5',
    value: 5,
  },
  {
    name: '6',
    value: 6,
  },
  {
    name: '7',
    value: 7,
  },
  {
    name: '8',
    value: 8,
  },
  {
    name: '9',
    value: 9,
  },
  {
    name: '10',
    value: 10,
  },
];
export const ClientStatusOptions = [
  { value: 1, name: 'Confidential' },
  { value: 2, name: 'Non Confidential' },
];
export const Service = [
  { value: 1, name: 'Exits' },
  { value: 2, name: 'New hire engagement' },
  { value: 3, name: 'Candidate engagement' },
  { value: 4, name: 'Renege' },
  { value: 5, name: 'Stay survey' },
];
export const CallingTagOptions = [
  { value: 3, name: 'Both' },
  { value: 2, name: 'Domestic' },
  { value: 1, name: 'International' },
];
export const OrientationsConductedOptions = [
  { value: false, name: 'No' },
  { value: true, name: 'Yes' },
];
export const FreqEngageProgramOptions = [
  { value: 1, name: 'Ongoing' },
  { value: 2, name: 'One time' },
  { value: 3, name: 'Quarterly' },
];
export const ReportTypeOptions = [
  { value: 2, name: 'Dashboard' },
  { value: 4, name: 'Excel report' },
  { value: 3, name: 'One Pager' },
  { value: 1, name: 'Static' },
];
export const ReportFreqOptions = [
  { value: 4, name: 'Annually' },
  { value: 3, name: 'Half yearly' },
  { value: 1, name: 'Monthly' },
  { value: 2, name: 'Quarterly' },
];
export const QueryStatusOptions = [
  { value: 'Closed', name: 'Closed' },
  { value: 'Open', name: 'Open' },
  { value: 'Response Received', name: 'Response Received' },
];
export const MyReportStatusList = [
  { value: 0, name: 'Pending' },
  { value: 1, name: 'In-progress' },
  { value: 2, name: 'Completed' },
  { value: 3, name: 'On-Hold' },
  { value: 4, name: 'Failed' },
];
export const CounselorCategory = [
  { name: 'New', value: 1 },
  { name: 'Old', value: 2 },
  { name: 'Both', value: 3 },
];
