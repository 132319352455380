import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import {
  AddressForm,
  AddressFormAccount,
  CountryStateCitySelect,
} from '@acengage/app/app/models/address-form.model';
import { SelectOption } from '@acengage/app/app/models/select.model';
import { CommonService } from '@acengage/app/app/services/common.service';
import { debounceTime, distinctUntilChanged, Subscription } from 'rxjs';
import * as AppActions from '@acengage/app/app/state/app.actions';
import { AppState } from '@acengage/app/app/state/app.state';
import { Config } from '@acengage/app/app/config';
import { UserBasicInformationForm } from '@acengage/app/app/users/models/add-users.model';

@Component({
  selector: 'acengage-address-form-fields',
  templateUrl: './address-form-fields.component.html',
  styleUrls: ['./address-form-fields.component.scss'],
})
export class AddressFormFieldsComponent implements OnInit {
  constructor(
    private commonService: CommonService,
    private appStore: Store<AppState>
  ) {}
  @Input() anotherCtrlName = '';
  @Input() isShowCheckbox = false;
  @Input() clientTab = false;
  @Input() isClient = false;
  @Input() addressFGContainer: FormGroup;
  _addressGroup: FormGroup<AddressFormAccount>;
  @Input() set addressGroup(formGroup: FormGroup<AddressFormAccount>) {
    this._addressGroup = formGroup;
    const { country, state } = formGroup.value;
    if (country) {
      this.getState(country, false);
    }

    if (state) {
      this.getCity(state, false);
    }
  }
  @Output() isSameClicked = new EventEmitter<boolean>();
  cities: Array<SelectOption> = [];
  states: Array<SelectOption> = [];
  countries: Array<SelectOption> = [];
  countryList = [];
  stateList = [];
  cityList = [];
  state: number | string;
  city: number | string;
  addressFGSubscription: Subscription;
  zipCodeRegex: RegExp = Config.alphanumWithoutSpace;

  ngOnInit(): void {
    this.addressFGSubscription = this._addressGroup.valueChanges
      .pipe(debounceTime(700), distinctUntilChanged())
      .subscribe((value: any) => {
        if (this.checkAnyCtrlHaveValue()) {
          this.commonService.checkSetValidator(
            this._addressGroup,
            'address_line1'
          );
          this.commonService.checkSetValidator(
            this._addressGroup,
            'address_line2'
          );
          this.commonService.checkSetValidator(this._addressGroup, 'country');
          this.commonService.checkSetValidator(this._addressGroup, 'state');
          this.commonService.checkSetValidator(this._addressGroup, 'city');
          this.commonService.checkSetValidator(this._addressGroup, 'zip_code');
        } else {
          this.commonService.checkClearValidator(
            this._addressGroup,
            'address_line1'
          );
          this.commonService.checkClearValidator(
            this._addressGroup,
            'address_line2'
          );
          this.commonService.checkClearValidator(this._addressGroup, 'country');
          this.commonService.checkClearValidator(this._addressGroup, 'state');
          this.commonService.checkClearValidator(this._addressGroup, 'city');
          this.commonService.checkClearValidator(
            this._addressGroup,
            'zip_code'
          );
        }
      });
    this.getCountry();
  }

  checkAnyCtrlHaveValue() {
    return (
      this._addressGroup.controls.address_line1.value ||
      this._addressGroup.controls.address_line2.value ||
      this._addressGroup.controls.country.value ||
      this._addressGroup.controls.state.value ||
      this._addressGroup.controls.city.value ||
      this._addressGroup.controls.zip_code.value
    );
  }

  checkDisable() {
    // console.log(this.addressFGContainer.controls[this.anotherCtrlName].valid)
    if (this.addressFGContainer.controls[this.anotherCtrlName].valid) {
      return false;
    } else {
      return true;
    }
  }

  getCountry() {
    this.showLoaderTrigger(true);
    this.commonService.getCountry().subscribe((res: any) => {
      this.countryList = res.data;
      this.countries = res.data.map((eachCountry: CountryStateCitySelect) => {
        const formSelectOption: SelectOption = {
          name: eachCountry.name,
          value: eachCountry.name,
        };
        return formSelectOption;
      });
      if (this.state) {
        this.getState(this.state, false);
      }
    });
    this.showLoaderTrigger(false);
  }

  getState(selectedCountry: number | string, clearValue = true) {
    this.state = selectedCountry;
    if (clearValue) {
      this._addressGroup.controls.state.setValue(null);
      this._addressGroup.controls.city.setValue(null);
    }
    if (selectedCountry && this.countryList.length) {
      const country: any = this.countryList.find(
        (res: any) => res.name == selectedCountry
      );
      this.showLoaderTrigger(true);
      this.commonService.getState(country?.id).subscribe((res: any) => {
        this.stateList = res.data;
        this.states = res.data.map((eachState: CountryStateCitySelect) => {
          const formSelectOption: SelectOption = {
            name: eachState.name,
            value: eachState.name,
          };
          return formSelectOption;
        });
        if (this.city) {
          this.getCity(this.city, false);
        }
      });
      this.showLoaderTrigger(false);
    }
  }

  getCity(selectedState: number | string, clearValue = true) {
    this.city = selectedState;
    if (clearValue) {
      this._addressGroup.controls.city.setValue(null);
    }
    if (selectedState) {
      this.showLoaderTrigger(true);
      const state: any = this.stateList.find(
        (res: any) => res.name == selectedState
      );
      this.commonService.getCity(state?.id).subscribe((res: any) => {
        this.cityList = res.data;
        this.cities = res.data.map((eachCity: CountryStateCitySelect) => {
          const formSelectOption: SelectOption = {
            name: eachCity.name,
            value: eachCity.name,
          };
          return formSelectOption;
        });
      });
      this.showLoaderTrigger(false);
    }
  }

  showLoaderTrigger(isShow: boolean) {
    this.appStore.dispatch(AppActions.showLoader({ showLoader: isShow }));
  }

  ngOnDestroy() {
    this.addressFGSubscription.unsubscribe();
  }
}
