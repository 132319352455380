import { selectedSubMenu, selectedMenu } from './../../state/app.actions';
import { getLoginState } from './../../state/app.selectors';
import { AppState } from 'apps/acengage/src/app/state/app.state';
import { MenuPayload } from './../../state/app.model';
import { getSideMenuState } from '@acengage/app/app/authentication/state/auth.selectors';
import { Store } from '@ngrx/store';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as fromActions from '@acengage/app/app/authentication/state/auth.actions';
import { Subscription } from 'rxjs';
import { SharedService } from '../services/shared.service';

@Component({
  selector: 'acengage-side-menubar',
  templateUrl: './side-menubar.component.html',
  styleUrls: ['./side-menubar.component.scss'],
})
export class SideMenubarComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    public sharedService: SharedService
  ) {}
  @Input() path = window.location.pathname;
  menus: MenuPayload[];
  subMenu = false;
  menutab: Subscription;
  selectMenu: number;
  selectSubMenu = 1;
  ngOnInit(): void {
    this.menutab = this.store.select(getLoginState).subscribe((res: any) => {
      this.menus = res.menuItem;
      console.log('side menu',res.selectedMenu)
      if (
        res.client_details.progress_of_completion === 11 &&
        res.roles[0].id === 11
      ) {
        this.selectMenu = 14;
      } else {
        this.selectMenu = res.selectedMenu;
      }

      if (res.selectedMenu === 5) {
        this.selectSubMenu = res.selectedSubMenu;
        this.subMenu = true;
      } else {
        this.subMenu = false;
      }
    });
  }
  navigateTo(item: any) {
    const sections = document.querySelectorAll('.menu-item');
    for (let i = 0; i < sections.length; i++) {
      sections[i].classList.remove('active');
    }
    item.isActive = true;
  }
  checkActiveByRoute(menu: any) {
    if (
      this.path.includes(menu.routePath) &&
      this.selectMenu == menu.main_menu_id
    ) {
      if (menu.routePath === '/services') {
        this.subMenu = true;
      } else {
        this.subMenu = false;
      }
      return true;
    } else {
      if (this.selectMenu === 13 && !menu.routePath) {
        this.subMenu = true;
        return true;
      } else {
        return false;
      }
    }
  }
  goTo(menu: any, subMenu: any, mainIndex: any, index?: any) {
    this.selectMenu = menu.main_menu_id;

    this.store.dispatch(
      fromActions.selectedMenu({
        selectedMenu: menu.main_menu_id,
        selectedSubMenu: subMenu.sub_menu_id,
      })
    );
    if (menu.sub_menu.length) {
      if (!index) {
        index = 0;
      }
      this.subMenu = true;
      if (menu.routePath) {
        this.router.navigate([menu.sub_menu[index].routePath], {
          queryParams: { p: 1, l: 30, sort: '', activeTab: 0 },
        });
        this.store.dispatch(
          fromActions.selectedMenu({
            selectedMenu: menu.main_menu_id,
            selectedSubMenu: menu.sub_menu[index].sub_menu_id
              ? menu.sub_menu[index].sub_menu_id
              : 1,
          })
        );
      } else {
        this.store.dispatch(
          fromActions.selectedMenu({
            selectedMenu: menu.main_menu_id,
          })
        );
      }
    } else {
      this.subMenu = false;
      this.selectMenu = menu.main_menu_id;
      this.selectSubMenu = menu.sub_menu[0]?.sub_menu_id
        ? menu.sub_menu[0]?.sub_menu_id
        : 1;
      this.router.navigate([menu.routePath], {
        queryParams: { p: 1, l: 30, sort: '' },
      });
    }
    if (subMenu) {
      this.selectSubMenu = subMenu.sub_menu_id;
      if (subMenu.is_hyperlink && subMenu.hyperlink) {
        window.open(`${subMenu.hyperlink}`);
      } else {
        this.router.navigate([subMenu.routePath], {
          queryParams: { p: 1, l: 30, sort: '', activeTab: 0 },
        });
      }
    }
  }

  ngOnDestroy() {
    this.menutab.unsubscribe();
    this.selectSubMenu = 1;
  }
  collapseMenu() {
    this.sharedService.collapseIcon = !this.sharedService.collapseIcon;
  }
}
