import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoginState, LOGIN_FEATURE_KEY} from './auth.reducer';

export const getLoginState = createFeatureSelector<LoginState>(LOGIN_FEATURE_KEY);


export const getLoginStates = createSelector(getLoginState, (state: LoginState) => ({
    token: state.token,
    id:state.id,
    default_password_changed:state.default_password_changed,
    first_login:state.default_password_changed,
    is_2fa:state.is_2fa,
    qr_text: state.qr_text,
    roles: state.roles,
    userDetails:state.userDetails,
    popup: state.popup,
    is_client_2fa_verified: state.is_client_2fa_verified,
    progress_of_completion: state.progress_of_completion
}));

export const getLoginError = createSelector(getLoginState, (state: LoginState) => ({
    error:state.error
}))

export const getForgot = createSelector(getLoginState, (state: LoginState) => ({ data: state.saveUser }));

export const getSideMenuState = createSelector(getLoginState, (state: LoginState) => ({ menusItem: state.menuItem}));

export const getProfileState = createSelector(getLoginState, (state: LoginState) => ({ userDetails: state.userDetails}));

export const getPermissionSideMenuState = createSelector(getLoginState, (state: LoginState) => {
  const permisionList = state.menuItem.filter(menuState => state.selectedMenu === menuState.main_menu_id)
  if(!permisionList.length){
    return []
  }
  const permissionRole = permisionList[0].permission_list.filter(permission => state.roles.find((eachRole)=>{
    return eachRole.name === permission.role
  }))
  return permissionRole
});
export const getPermission1 = createSelector(getLoginState, (state: LoginState) => {
  const permisionList = state.menuItem.filter(menuState => 1 === menuState.main_menu_id)
  if(!permisionList.length){
    return []
  }
  const permissionRole = permisionList[0].permission_list.filter(permission => state.roles.find((eachRole)=>{
    return eachRole.name === permission.role
  }))
  return permissionRole
});

export const getPermissionSideSubMenuState = createSelector(getLoginState, (state: LoginState) => {
  const permisionList = state.menuItem.filter(menuState => menuState.main_menu_id === 5)
  if(!permisionList.length){
    return []
  }
  const permission = permisionList[0].sub_menu.filter(submenuState => state.selectedSubMenu === submenuState.sub_menu_id)
  const permissionRole = permission[0]?.permission_list?.filter(permission => state.roles.find((eachRole)=>{
        return eachRole.name === permission.role
      }))
  return permissionRole
});
