import { Store } from '@ngrx/store';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { CommonButtonInput } from '../../models/button.model';
import * as fromActions from '@acengage/app/app/authentication/state/auth.actions';

@Component({
  selector: 'acengage-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  constructor(private router: Router, private store: Store) {}
  items: MenuItem[];
  menuList: any;
  @Input() isShowHome: boolean = true;
  @Input() homeItem: MenuItem = { icon: 'pi pi-home' };
  @Input() detailPage: boolean = false;
  @Input() editNcBtn = false;

  @Input() isFromParentComp: boolean = false;
  @Input() set pathItems(value: any) {
    this.menuList = value;
    this.items = value;
  }
  @Input() buttonDetails: CommonButtonInput[] = [];
  @Output() itemClicked = new EventEmitter<MenuItem>();
  @Output() buttonClicked = new EventEmitter<string>();

  ngOnInit(): void {}

  navigateTo({ ...event }: any) {
    if (this.isFromParentComp) {
      this.itemClicked.emit(event?.item);
    } else {
      if (event?.item?.icon?.includes('home')) {
        let moduleHomeRoute: string = this.router.url.split('/')[1];
        if (moduleHomeRoute === 'services') {
          const redirectUrl: any = this.menuList[1]['routerLink']
            ? this.menuList[1]['routerLink']
            : moduleHomeRoute;
          if (redirectUrl) {
            moduleHomeRoute = redirectUrl;
          }
        }
        if (moduleHomeRoute === 'users') {
          this.store.dispatch(fromActions.selectedMenu({ selectedMenu: 3 }));
        }
        this.router.navigate([`/${moduleHomeRoute}`], {
          queryParams: { p: 1 },
        });
      } else {
        if (event?.item?.label === 'Users') {
          this.store.dispatch(fromActions.selectedMenu({ selectedMenu: 3 }));
        }
        if (event?.item?.queryParams) {
          this.router.navigate([event?.item['routerLink']], {
            queryParams: { ...event.item.queryParams },
          });
        } else {
          this.router.navigate([event?.item['routerLink']], {
            queryParams: { p: 1, l: 30, sort: '' },
          });
        }
      }
    }
  }
}
