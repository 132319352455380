import {
  Component,
  forwardRef,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  HostListener,
  ViewChild,
} from '@angular/core';
import { Subject, debounceTime } from 'rxjs';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValueAccessorService } from '../../shared-service/valueAccessor.service';
@Component({
  selector: 'acengage-multi-select-lazy',
  templateUrl: './multi-select-lazy.component.html',
  styleUrls: ['./multi-select-lazy.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectLazyComponent),
      multi: true,
    },
  ],
})
export class MultiSelectLazyComponent extends ValueAccessorService {
  @ViewChild('scrollMe', { static: false }) myScrollContainer: ElementRef;
  OptionList: any = [];
  @Input() set options(value: any) {
    this.OptionList = value;
    this.isbottom = true;
    if (this.selectedOptions.length > 0) {
      this.OptionList.forEach((eachData: any) => {
        const findIndex = this.selectedOptions.indexOf(
          eachData[this.optionValue]
        );
        if (eachData[this.optionValue] === this.selectedOptions[findIndex]) {
          eachData['checked'] = [eachData[this.optionValue]];
        }
      });
    }
  }
  @Input() optionLabel: any;
  @Input() optionValue: any;
  @Input() isCategoryDrpDwn: boolean;
  @Input() placeHolder = 'Select';
  @Input() override disabled = false;
  @Input() classesToAdd = '';
  @Input() name = '';
  @Input() isShowFloatLabel = true;
  @Input() lazyConfig: { page: number; limit?: number; search: string } = {
    page: 1,
    limit: 10,
    search: '',
  };
  selectedCampaignName = '';
  floatLabel = '';
  @Input() set label(value: any) {
    if (value) {
      this.floatLabel = value;
      this.selectedCampaignName = value;
    }
  }
  @Input() showDropdown = false;
  @Input() set selectedNames(value: any) {
    if (value) {
      const labels = value.split(',');
      this.labelNames = labels;
    }
  }
  textValue = '';
  @Input() set searchtext(search: any) {
    this.textValue = search;
    // if(search){
    const event = {
      target: {
        value: search,
      },
    };
    this.onLazyFilter(event);
    // }
  }
  @Output() selectedData = new EventEmitter<any>();
  tempData: any = [];
  labelNames: any = [];
  toggle = false;
  isbottom = true;
  @Input() clearFilter = true;
  selectedOptions: any[] = [];
  @Output() closeDropdown = new EventEmitter<any>();
  @Output() getNextOptions = new EventEmitter<{
    page: number;
    search: string;
  }>();
  searchSubject$ = new Subject<string>();

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.searchSubject$
      .pipe(debounceTime(500))
      .subscribe((searchValue: string) => {
        this.lazyConfig = {
          ...this.lazyConfig,
          page: 1,
          search: searchValue || '',
        };
        this.getNextOptions.emit(this.lazyConfig);
      });
      // if(this.clearFilter) {
      // }
      // this.clearFilters()
  }

  clearFilters() {
    this.selectedCampaignName = this.floatLabel;
    this.labelNames = [];
    this.selectedOptions = [];
    this.OptionList.forEach((eachData: any) => {
      eachData['checked'] = [];
    });
    this.updateValue(this.selectedOptions);
    this.selectedData.emit(this.selectedOptions ? this.selectedOptions : []);
  }

  setCampaignName(isClose?: boolean) {
    if (isClose) {
      if (
        this.selectedCampaignName !== this.floatLabel &&
        this.selectedCampaignName !== ''
      ) {
        this.selectedCampaignName = this.selectedCampaignName;
      } else {
        this.selectedCampaignName = this.floatLabel;
      }
    } else {
      if (
        this.selectedCampaignName === this.floatLabel &&
        this.isShowFloatLabel
      ) {
        this.selectedCampaignName = '';
        this.labelNames = [];
      }
    }
  }
  @Input() set selectedID(value: any) {
    if (value) {
      if (value.length === this.OptionList.length) {
        this.toggle = true;
      }
      const selectedIds = value;
      this.selectedOptions = selectedIds;
      if (!this.selectedOptions.length) {
        this.selectedCampaignName = this.floatLabel;
        this.toggle = false;
        this.OptionList.forEach((eachData: any) => {
          eachData['checked'] = [];
        });
      } else {
        this.OptionList.forEach((eachData: any) => {
          const findIndex = this.selectedOptions.indexOf(
            eachData[this.optionValue]
          );
          if (eachData[this.optionValue] === this.selectedOptions[findIndex]) {
            eachData['checked'] = [eachData[this.optionValue]];
            this.labelNames.push(eachData[this.optionLabel]);
          }
        });
        this.selectedCampaignName = this.labelNames
          ? this.labelNames.toString()
          : '';
      }
    }
  }

  moveScrollBottom() {
    const element: any = this.myScrollContainer.nativeElement;
    const height: any = element.scrollHeight - element.scrollTop;
    const atBottom: any =
      parseInt(height) === element.clientHeight ||
      parseInt(height) <= element.clientHeight + 20;
    if (atBottom && this.isbottom) {
      this.isbottom = false;
      this.lazyConfig.page++;
      this.getNextOptions.emit(this.lazyConfig);
      return;
    }
  }
  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
    this.moveScrollBottom();
  }

  sendSelectData(data: any): void {
    this.toggle = false;
    const findIndex = this.selectedOptions.indexOf(data[this.optionValue]);
    if (findIndex === -1) {
      this.selectedOptions.push(data[this.optionValue]);
      this.labelNames.push(data[this.optionLabel]);
      data['checked'] = [data[this.optionValue]];
    } else {
      this.selectedOptions.splice(findIndex, 1);
      this.labelNames.splice(findIndex, 1);
      data['checked'] = [];
    }
    if (this.selectedOptions.length) {
      this.selectedCampaignName = '';
    } else {
      this.selectedCampaignName = this.floatLabel;
    }
    this.selectedCampaignName = this.labelNames
      ? this.labelNames.toString()
      : '';
    this.updateValue(this.selectedOptions);
    this.selectedData.emit(this.selectedOptions ? this.selectedOptions : []);
  }

  onLazyFilter(event: any, isTypedVal = false) {
    if (
      isTypedVal ||
      (!isTypedVal && this.textValue !== this.lazyConfig.search)
    ) {
      this.searchSubject$.next(event?.target?.value || '');
    }
  }

  updatechange(event: any, control: any) {
    // console.log(event);
  }

  toggleAll(event: any) {
    if (event.checked) {
      this.toggle = true;
      this.OptionList.forEach((eachData: any) => {
        this.selectedOptions.push(eachData[this.optionValue]);
        this.labelNames.push(eachData[this.optionLabel]);
        eachData['checked'] = [eachData[this.optionValue]];
      });
    } else {
      this.toggle = false;
      this.selectedCampaignName = this.label;
      this.OptionList.forEach((eachData: any) => {
        this.selectedOptions = [];
        this.labelNames = [];
        eachData['checked'] = [];
      });
    }

    if (this.selectedOptions.length) {
      this.selectedCampaignName = this.labelNames
        ? this.labelNames.toString()
        : '';
    }
    this.updateValue(this.selectedOptions);
    this.selectedData.emit(this.selectedOptions ? this.selectedOptions : []);
  }

  formValueToShow(eachMultiData: any) {
    if (this.optionLabel) {
      return `${eachMultiData[this.optionLabel]}`;
    } else {
      return eachMultiData.name;
    }
  }
}
