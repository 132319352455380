import { getLoginState } from './../../state/app.selectors';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../core/services';
import { SharedService } from '../services/shared.service';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as appAction from '@acengage/app/app/state/app.actions';
import { notificationList } from '../../notification/models/notification-response.interface';
import { MenuPayload, Role, SubMenu } from '../../state/app.model';
import { LoginState } from '../../authentication/state/auth.reducer';
import { environment } from '@acengage/app/environments';
import { NotificationService } from '../../notification/services/notification.service';
import { CommonAPIRequest } from '../../models/common-api-request.model';

@Component({
  selector: 'acengage-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit {
  showContextMenu = false;
  constructor(
    public authService: AuthenticationService,
    private router: Router,
    public sharedService: SharedService,
    private store: Store,
    private notificationService: NotificationService
  ) {}
  userName = '';
  imagePic = '';
  userDetails = {} as LoginState;
  userPersonalInfo: any = {};
  userDetailSubscription: Subscription;
  newCount: number;
  notificationData: Array<notificationList> = [];
  isShowForceLogoutPopup = false;
  notificationCntWebsocket: WebSocket;
  apiReqDtls: CommonAPIRequest = {
    page: 1,
    limit: 10,
    search: '',
    sort_order: '',
  };
  clientDetails:any;
  noDetail=false;
  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('login') || '');
    // this.notificationList();
    this.userDetailSubscription = this.store
      .select(getLoginState)
      .subscribe((res) => {
        this.userPersonalInfo = res?.userDetails?.personal_info || {};
        if (this.userPersonalInfo?.picture?.picture_name) {
          this.imagePic = this.userPersonalInfo?.picture?.picture;
        } else {
          const userLoginDtls = JSON.parse(localStorage.getItem('login') || '');
          if (userLoginDtls?.picture) {
            this.imagePic = userLoginDtls?.picture;
          } else {
            this.userName =
              userLoginDtls?.username?.charAt(0)?.toUpperCase() || '';
          }
        }
        if(res?.client_details){
          if(res.client_details?.progress_of_completion){
            this.clientDetails=res.client_details 
          }else{
            this.noDetail=true;
          }
        }
      });

    this.notificationCount();
  }
  goToHelp(){
    window.open('https://www.acengage.com/acengage-employee-intranet/getHelp.html','_blank')
  }
  // notificationList() {
  //   this.store.dispatch(appAction.showLoader({ showLoader: true }));
  //   this.notificationService.notification().subscribe(
  //     (result: any) => {
  //       this.store.dispatch(appAction.showLoader({ showLoader: false }));
  //       if (result) {
  //         this.notificationData = result.data;
  //         this.newCount = 0;
  //         this.notificationData.forEach((eachRow: notificationList) => {
  //           if (eachRow.read_status === 0) {
  //             this.newCount++;
  //           }
  //           // this.store.dispatch(authActions.notificationDtl({ payload: {} }));
  //         });
  //       }
  //     },
  //     (err) => {
  //       this.store.dispatch(appAction.showLoader({ showLoader: false }));
  //     }
  //   );
  // }

  connectWebsocket() {
    const socketUrl = `${environment.socket_url}/notifications?user_id=${this.userDetails.id}`;

    this.notificationCntWebsocket = new WebSocket(socketUrl);

    this.notificationCntWebsocket.addEventListener(
      'open',
      (connectResp: any) => {}
    );

    this.notificationCntWebsocket.addEventListener('message', (msgResp) => {
      const data = msgResp?.data ? JSON.parse(msgResp?.data) : {};
      if (data) {
        this.newCount = data?.count || data?.payload?.count || 0;
      }
    });

    this.notificationCntWebsocket.addEventListener('error', (event) => {});

    this.notificationCntWebsocket.addEventListener('close', (event) => {});
  }

  notificationCount() {
    this.store.dispatch(appAction.showLoader({ showLoader: true }));
    this.notificationService.getNotificationCount().subscribe(
      ({ data }: any) => {
        if (data) {
          this.newCount = data.unread_count;
        }
        this.store.dispatch(appAction.showLoader({ showLoader: false }));
      },
      (err) => {
        this.store.dispatch(appAction.showLoader({ showLoader: false }));
      }
    );
  }

  toggleProfileIcon(isVisible: boolean) {
    this.showContextMenu = !this.showContextMenu;
  }
  navigateToUrl(data: string) {
    if (data === 'Profile') {
      this.router.navigate(['/setting/profile-detail']);
    } else if (data === 'subscription') {
      this.router.navigate(['/setting/subscription-detail']);
    } else {
      this.router.navigate(['/setting/change-password']);
    }
  }

  logoutProcess() {
    if (
      this.userDetails?.roles?.some(
        (eachRole: Role) => eachRole.name === 'Counsellor'
      ) &&
      this.checkCounsellorHasExitService()
    ) {
      this.store.dispatch(appAction.showLoader({ showLoader: true }));
      this.authService
        .assignedCampaignsTodayCount()
        .subscribe((assignedCountResp: any) => {
          this.store.dispatch(appAction.showLoader({ showLoader: false }));
          if (assignedCountResp?.assigned_count) {
            this.isShowForceLogoutPopup = true;
          } else {
            this.authService.logOut();
          }
        });
    } else if (
      this.userDetails?.roles?.some(
        (eachRole: Role) => eachRole.name === 'Client Admin'
      )
    ) {
      this.store.dispatch(appAction.showLoader({ showLoader: true }));
      this.authService
        .assignedCampaignsTodayCount()
        .subscribe((assignedCountResp: any) => {
          this.store.dispatch(appAction.showLoader({ showLoader: false }));
          if (assignedCountResp?.assigned_count) {
            this.isShowForceLogoutPopup = true;
          } else {
            // this.authService.clientLogOut();
            this.authService.logOut();
          }
        });
    } else {
      this.authService.logOut();
    }
  }

  checkCounsellorHasExitService() {
    const serviceMenu: MenuPayload = this.userDetails?.menuItem?.filter(
      (eachMenu: MenuPayload) => eachMenu.name === 'Services'
    )[0];
    if (
      serviceMenu &&
      serviceMenu?.sub_menu?.length &&
      serviceMenu?.sub_menu?.some(
        (eachSubmenu: SubMenu) => eachSubmenu.name === 'Exits'
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  toggleNotification() {
    this.newCount = 0;
    setTimeout(() => {
      this.router.navigateByUrl('/notification');
    }, 100);
  }
  ngOnDestroy() {
    // this.notificationCntWebsocket.close();
    this.userDetailSubscription.unsubscribe();
  }
}
