import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { loaderService } from './services/loader.service';
import { Store } from '@ngrx/store';
import { getToasterState, getLoaderState } from './state/app.selectors';
import { ToasterConfig } from './models/toaster.model';
import * as AppActions from './state/app.actions';
import { NavigationStart, Router } from '@angular/router';
import { getLoginStates, getProfileState } from './authentication/state/auth.selectors';
import * as fromActions from '@acengage/app/app/authentication/state/auth.actions';
import { environment } from '@acengage/app/environments';
import { LoginState } from '@acengage/app/app/authentication/state/auth.reducer';
@Component({
  selector: 'acengage-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'acengage';
  showLoader = false;
  displayToast: boolean | undefined = false;
  toasterInfo: ToasterConfig = {
    class: 'error',
    header: '',
    message: '',
    showToaster: true
  };
  path: string;
  reminder:any={};
  confimationMessage = '';
  showConfirmationDialog = false;
  callerConnectWebsocket: WebSocket;
  loading = false;
  showInitiateCallDialog:boolean = false;
  showInitiateReminder:boolean = false;
  isOutgoingCall:boolean = false;
  outgoingError:boolean = false;
  dialedCandidate: any = {};
  iconIndex:number = 5;
  isCallerError:string='';
  ServiceName:string='';
  callerMessage:string='Initiating Call...';
  iconItems: any[] = [
     { className: 'agent-connected', info:'Counsellor Connected!' },
     { className: 'call-declined', info:'Missed the Call!' },
     { className: 'call-declined', info:'Call Declined!' },
     { className: 'call-connected', info:'Call Connected!' },
     { className: 'call-declined', info:'Call Disconnected!' },
     { className: 'call-initiate', info:'Initiating Call...' },
     { className: 'call-initiate', info:'Incoming Call...' },
     { className: 'call-connected', info:'Call Connected!' },
     { className: 'call-declined', info:'Missed the Call!' },
     { className: 'call-declined', info:'Call Cancelled!' },
     { className: 'call-declined', info:'Call Disconnected!' },
  ];
  loggedInUserDtls: LoginState;
  callerModal:any={
    first_name:'',
    mobile:''
  }
  @ViewChild('mainContent') mainContentElement: ElementRef;

  constructor(public _loader: loaderService, private store: Store, private router: Router) {
  }

  ngOnInit() {
    this.store.select(getToasterState).subscribe((toasterState) => {
      this.toasterInfo = {
        class: toasterState.toasterConfig.class,
        header: toasterState.toasterConfig.header,
        message: toasterState.toasterConfig.message,
        showToaster: toasterState.toasterConfig.showToaster
      };
      this.displayToast = toasterState.toasterConfig.showToaster;
    });

    this.store.select(getLoaderState).subscribe((loaderState) => {
      this.showLoader = loaderState.showLoader;
    });

    this.store.select(getLoginStates).subscribe((res: any) => {
      // console.log('--------');
      // console.log('getLoginStates',res);
      // console.log('--------');
      if (res?.token) {
        this.loggedInUserDtls = JSON.parse(localStorage.getItem('login') || '');
        if (this.callerConnectWebsocket) {
          console.log('web socket clearing time::',new Date());
          console.log('--------');
          this.callerConnectWebsocket.close();
          this.connectWebsocket(this.loggedInUserDtls?.id);
        }
        else{
          this.connectWebsocket(this.loggedInUserDtls?.id);
        }
        if (!res?.userDetails?.personal_info?.user_profile_present && res?.popup) {
          this.confimationMessage = `Do you want update your profile?`;
          this.showConfirmationDialog = true;
        } else {
          this.showConfirmationDialog = false;
        }
      }
      else{
        console.log('--------');
        console.log('no token found clearing websocket',res);
        console.log('--------');
        this.closeWebsocket();
      }
    })

    document.addEventListener('keypress', (evt: Event) => {
      if (this.showLoader) {
        evt.preventDefault();
      }
    })
  }
  updtStatusOfUsers(data: any) {
    if (data === 'edit') {
      this.showConfirmationDialog = false
      this.store.dispatch(fromActions.showPopup({ popup: false }))
      this.router.navigate([`/setting/profile`])
    } else if (data === 'skip') {
      this.store.dispatch(fromActions.showPopup({ popup: false }))
      this.showConfirmationDialog = false
    }
  }
  hideToaster(value: boolean) {
    this.store.dispatch(AppActions.showToaster(this.toasterInfo = { ...this.toasterInfo, showToaster: value }));
  }
  getServiceName(id:number){
    switch (id) {
      case 1:
        this.ServiceName = 'Exits';
        break;
    
      case 2:
        this.ServiceName = 'New Hire Engagement';
        break;
    
      case 3:
        this.ServiceName = 'Candidate Engagement';
        break;
    
      case 4:
        this.ServiceName = 'Renege';
        break;
    
      case 5:
        this.ServiceName = 'Stay Survey';
        break;
    
      default:
        break;
    }
  }
  initiateCall(e:any){
    this.dialedCandidate=e;
    this.getServiceName(e?.service_id);
    this.callerModal.first_name = e.employee_name;
    this.callerModal.mobile = e.mobile;
    this.showInitiateCallDialog = true;
    this.showInitiateReminder = false;
  }
  initiateCallIncoming(e:any){
    this.dialedCandidate=e;
    this.callerModal.first_name = '';
    this.callerModal.mobile = e.number;
    this.showInitiateCallDialog = true;
    this.showInitiateReminder = false;
  }
  initiateReminder(e:any){
    this.reminder = {};
    this.reminder = e;
    this.showInitiateCallDialog = false;
    setTimeout(() => {
    this.showInitiateReminder = true;
    this.playAudio();
    }, 50);
  }
  playAudio(){
    let audio = new Audio();
    audio.src = "../assets/files/notification.mp3";
    audio.load();
    audio.play();
  }
  closeReminder(){
    this.showInitiateReminder = false;
  }
  connectWebsocket(id:any) {
    const socketUrl = `${environment.socket_url}/smartflo?uid=smartflo_${id}`;

    this.callerConnectWebsocket = new WebSocket(socketUrl);

    this.callerConnectWebsocket.addEventListener(
      'open',
      (connectResp: any) => {
        console.log('--------');
        console.log('web socket open',connectResp);
        console.log('--------');
      }
    );

    this.callerConnectWebsocket.addEventListener('message', (msgResp) => {
      const data = msgResp?.data ? JSON.parse(msgResp?.data) : {};
      if (data.payload) {
        if(data.payload.code === 200 || data.payload.code === 'ringing' || data.payload.code === 'noanswer' || data.payload.code === 'cancel' || data.payload.code === 'answered' || data.payload.code === 'disconnected')
          {
            this.outgoingError = false;
            if(data.payload?.type==="smartflo_outgoing"){
              this.isCallerError = '';
              this.iconIndex = data.payload?.interview_status;
              this.callerMessage = this.iconIndex === 0 ?data.payload?.message:data.payload?.message;
              this.isOutgoingCall = true;
            }
            if(data.payload?.type==="smartflo_outgoing" && data.payload?.interview_status === 100){
              this.initiateCall(data.payload?.candidate_details);
            }
            if(data.payload?.type==="reminder"){
              this.initiateReminder(data.payload);
            }
            if(data.payload?.type==="smartflo_incoming"){
              this.isCallerError = '';
              const copyIconIndex = this.iconIndex;
              this.iconIndex = data.payload?.code === 'ringing'?6:(data.payload?.code === 'answered'?7:(data.payload?.code === 'cancel'?9:(data.payload?.code === 'disconnected'?10:8)));
              if(copyIconIndex ===7 && this.iconIndex ===8){
                this.iconIndex = 4;
              }
              this.callerMessage = this.iconItems[this.iconIndex].info;
              this.isOutgoingCall = false;
              if(this.iconIndex === 6)this.initiateCallIncoming(data.payload);
            }
          }
          else if(data.payload.code === 400 && data.payload?.type==="smartflo_outgoing"){
            this.isOutgoingCall = true;
            this.outgoingError = true;
            this.callerMessage = '';
            this.isCallerError = data.payload?.message;
            this.iconIndex = data.payload?.interview_status === 0 ?4:data.payload?.interview_status;
          }
          else{
            this.outgoingError = false;
            this.callerMessage = '';
            this.isCallerError = data.payload?.message;
            this.iconIndex = data.payload?.interview_status === 0 ?4:data.payload?.interview_status;
            // this.closeWebsocket();
          }
      }
      console.log('--------');
      console.log('web socket message',data);
      console.log('web socket message time::',new Date());
      console.log('--------');
    });

    this.callerConnectWebsocket.addEventListener('error', (event) => {
      console.log('--------');
      console.log('web socket error',event);
      console.log('web socket error time::',new Date());
      console.log('--------');
    });

    this.callerConnectWebsocket.addEventListener('close', (event) => {
      console.log('--------');
      console.log('web socket close',event);
      console.log('web socket close time::',new Date());
      console.log('--------');
    });
  }
  closeWebsocket() {
    if (this.callerConnectWebsocket) {
      console.log('--------');
      console.log('web socket close function called time :: ',new Date());
      console.log('--------');
      this.callerConnectWebsocket.close();
      this.clearModal();
    }
  }
  closeModal(e:boolean){
    console.log('close modal clicked',e);
    if(e===false){
      this.showInitiateCallDialog = false;
      this.dialedCandidate='';
      this.callerModal.first_name = '';
      this.callerModal.mobile = '';
      this.iconIndex=5;
      this.callerMessage = 'Initiating Call...';
      this.isCallerError = '';
    }
  }
  clearModal(){
    this.showInitiateCallDialog = false;
      this.dialedCandidate='';
      this.callerModal.first_name = '';
      this.callerModal.mobile = '';
      this.iconIndex=5;
      this.callerMessage = 'Initiating Call...';
      this.isCallerError = '';
      this.closeReminder();
  }
  rowSelection(event: any){
    console.log('event',event);
    // let selectedCandidate = event?.selectedRows[0];
    let selectedCandidate = event;
    if(selectedCandidate?.service_id === 3){
    this.navigateToCEInterventionDtls(selectedCandidate);
    }
    else if(selectedCandidate?.service_id === 2){
    this.navigateToNHEInterventionDtls(selectedCandidate);
    }
    else{
      if(this.outgoingError){
        this.navigateToExitInterventionBasicDtls(selectedCandidate);

      }else{
        this.navigateToExitInterventionDtls(selectedCandidate);
      }
    }
    setTimeout(() => {
      this.clearModal();
    }, 100);
  }
  navigateToCEInterventionDtls(selectedRecord: any) {
    this.router.navigate(['/services/candidate-engagement/interventions'], {
      queryParams: { campaignID: selectedRecord.campaign_id, empID: selectedRecord.record_id,campaignName:selectedRecord.campaign_name,candidateName:selectedRecord.employee_name,intervention:selectedRecord.intervention_id,isContinuity:true,isReload:true },
    });
  }
  navigateToNHEInterventionDtls(selectedRecord: any) {
    this.router.navigate(['/services/hire-engagement/interventions'], {
      queryParams: { campaignID: selectedRecord.campaign_id, empID: selectedRecord.record_id,campaignName:selectedRecord.campaign_name,candidateName:selectedRecord.employee_name,intervention:selectedRecord.intervention_id,isContinuity:true,isReload:true},
    });
  }
  navigateToExitInterventionDtls(selectedRecord: any) {
    this.router.navigate(['/services/view-exit-campaign'], {
      queryParams: { service_id: selectedRecord.campaign_id, type 
      : 'Exit', recordId: selectedRecord.record_id,activeTab:3,interviewPage:true,campaignName:selectedRecord.campaign_name,candidateName:selectedRecord.employee_name,isContinuity:true,activeSubTab:1,isReload:true },
    });
  }
  navigateToExitInterventionBasicDtls(selectedRecord: any) {
    this.router.navigate(['/services/view-exit-campaign'], {
      queryParams: { service_id: selectedRecord.campaign_id, type 
      : 'Exit', recordId: selectedRecord.record_id,activeTab:3,interviewPage:true,campaignName:selectedRecord.campaign_name,candidateName:selectedRecord.employee_name,isContinuity:true,activeSubTab:0,isReload:true },
    });
  }
}
