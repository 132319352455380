import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Store } from '@ngrx/store';
import * as AppActions from '../../../../../apps/acengage/src/app/state/app.actions';

@Component({
  selector: 'acengage-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
})
export class ToasterComponent implements OnInit {
  constructor(private messageService: MessageService,
    private store:Store
    ) { }
  toasterDetails: any = {
    class: 'success',
    header: 'Service Message',
    message: 'Toaster Details',
    showToaster:true
  };
  displayPopup=false;
  toastrPosition = "top-right";
  @Input() set toasterInfos(value: any) {
    if(value.showToaster){
      if( this.toasterDetails !== value){
        this.toasterDetails = value;
        if(this.toasterDetails['class']=='error' && (this.toasterDetails['header'] || this.toasterDetails['message'])){
          if(this.toasterDetails['message'] != "Duplicate Records found" && this.toasterDetails['message'] != "Field Mismatch") {
            this.displayPopup=true;
          } else {
            this.displayPopup=false;
          }
        }
      }
    }
   
  }

  @Input() set toasterPosition(value: any) {
    this.toastrPosition = value;
  }
  @Input() set displaytoaster(value: any) {
    if (value) {
      if(this.toasterDetails['class']=='success'){
        this.showToaster();
      }else if(this.toasterDetails['class']=='error' && (this.toasterDetails['header'] || this.toasterDetails['message'])){
        if(this.toasterDetails['message'] != "Duplicate Records found" && this.toasterDetails['message'] != "Field Mismatch") {
          this.displayPopup=true;
        } else {
          this.displayPopup=false;
        }
      }
    }
  }
  @Output() hideToaster = new EventEmitter<boolean>();

  ngOnInit(): void { }

  isArray(value: any): boolean {
    return Array.isArray(value);
  }

  showToaster() {
    this.messageService.add({ severity: this.toasterDetails['class'], summary: this.toasterDetails['header'], detail: this.toasterDetails['message'] });
  }

  closePopup(){
    this.displayPopup=false;
    this.toasterDetails = {
      class: '',
      header: '',
      message: ''
    };
    this.store.dispatch(AppActions.showToaster({
      class: '',
      header: '',
      message: '',
      showToaster: false
    }));
  }

  toasterClose() {
    this.hideToaster.emit(false);
  }
}
